html, body{
  height: 100%;
}


#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Pokedex{
  background-color: rgb(253, 0, 42);
  box-shadow: 0px 10px 30px 0px rgba(253, 0, 42, 0.5);
  width: 400px;
  height: 500px;
  border: 2px black solid;
  border-radius: 15px;
}

.inputs{
  display: flex;
  margin-top: 10px;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 40px;
}

.inputs .nombre{
  border: none;
  margin-top: 5px;
  margin-left: 60px;
  border-radius: 5px;
  height: 30px;
}

.inputs .btn{
  border: 4px white solid;
  background-color: aqua;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.inputs .btn:active{
  border: 5px white solid;
}

.marco{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 75px;
  margin-top: 30px;
  width: 250px;
  height: 250px;
  background-color: rgb(194, 192, 192);
  border: 1px black solid; 
  border-radius: 10px;
  border-bottom-left-radius: 30px;    
}

.marcoI{
  width: 190px;
  height: 190px;
  background-color: white;
  border: 1px black solid;
  border-bottom-left-radius: 10px;
}

.marcoI img{
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 6px;
}

.stats{
  background-color: rgb(0, 92, 0);
  padding-left: 10px;
  padding-top: 10px;
  height: 120px;
  width: 290px;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 50px;

}

.stats p{
  color: yellow;
  display: inline;
}

.stats table{
  font-size: 10px;
  margin-left: 10px;
  width: 250px;
  height: 50px;
  text-align: center;
  color: yellow;
}

.stats table th{
  width: 40px;
}


